const config = {
  siteTitle: 'SQRL', // Site title.
  siteTitleShort: 'SQRL', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'SQRL', // Alternative site title for SEO.
  siteLogo: '/logos/sqrl-app-icon@2x.png', // Logo used for SEO and manifest.
  siteUrl: 'https://sqrl.me', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: 'SQRL - step into savings', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteFBAppID: '1825356251115265', // FB Application ID for using app insights
  googleAnalyticsID: 'UA-47311644-5', // GA tracking ID.
  facebookPixelID: '415573749054722', // Pixel ads tracking ID.
  typeformSurveyURL: 'https://om43.typeform.com/to/fI7TJU', // URL of the survey on TypeForm
  //disqusShortname: 'https-vagr9k-github-io-gatsby-advanced-starter', // Disqus shortname.
  postDefaultCategoryID: 'Tech', // Default category for posts.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  //userName: 'Advanced User', // Username to display in the author segment.
  //userEmail: 'AdvancedUser@example.com', // Email used for RSS feed's author segment
  userTwitter: 'sqrlme', // Optionally renders "Follow Me" in the UserInfo segment.
  //userLocation: 'North Pole, Earth', // User location to display in the author segment.
  //userAvatar: 'https://api.adorable.io/avatars/150/test.png', // User avatar to display in the author segment.
  //userDescription:
  //  "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  copyright: 'Copyright © 2019. SQRL', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
  seo: {
    og: {
      description:
        'SQRL offers a simple way for you to build your emotional, physical and financial well-being.',
      image: 'https://sqrl.me/images/seo/fb_image.png',
      title: 'SQRL: Own your holistic well-being',
      url: 'https://sqrl.me',
      appID: 'sqrlme'
    },
    twitter: {
      title: 'SQRL: Own your holistic well-being',
      image: 'https://sqrl.me/images/seo/twitter_image.png',
      description:
        'SQRL offers a simple way for you to build your emotional, physical and financial well-being.',
      type: 'summary_large_image',
      site: '@sqrlme'
    }
  }
}

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/')
  config.siteRss = `/${config.siteRss}`

module.exports = config
